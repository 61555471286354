import React, { useContext, useEffect, useMemo, useState } from "react";
import {
    BodyContent,
    ButtonLink,
    FormContent,
    ProductContent,
    ProductQuantity,
    TitleContent
} from "./styled";
import ContainerAuthenticated from "containers/Authenticated";
import Footer from "components/Footer";
import { Content, Load, LoadCenter, Title } from "ui/styled";
import Select from "components/Form/Select";
import SideBar from "components/SideBar";
import ProductCard from "components/ProductCard";
import { sortOptions } from "utils/options";
import { drinks } from "utils/drinks";
import PaymentMethods from "components/PaymentMethods";
import Brands from "components/Brands";
import useWindowSize from "utils/useWindowSize";
import { Read } from "services/products";
import { Read as ReadSkus } from "services/skus";
import { normalizeStrapiList } from "utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { CoreContext } from "context/CoreContext";

export default function Products() {
    const location = useLocation();

    const [sortOption, setSortOption] = useState(null);
    const [isResponsiveActive, setIsResponsiveActive] = useState(false);
    const windowSize = useWindowSize();

    const { cat, sup, categories } = useContext(CoreContext)
    
    const [search, setSearch] = useState("")

    const [skus, setSkus] = useState([])
    const [products, setProducts] = useState([])

    const [loading, setLoading] = useState(false)

    const handleSortChange = (selectedSortOption) => {
        setSortOption(selectedSortOption);
    };

    const handleResponsive = () => {
        setIsResponsiveActive(!isResponsiveActive);
    };

    const init = async () => {
        if(!loading){
            setLoading(true)
                const result_sku = await ReadSkus()
                const normalResult_sku = normalizeStrapiList(result_sku)
                console.log("normalResultSkus", normalResult_sku)
                setSkus(normalResult_sku)
            
                const result = await Read()
                const normalResult = normalizeStrapiList(result)
                const parsedResult = normalResult?.map(m => ({ 
                    ...m, sku: normalResult_sku
                    ?.find(f => f?.products?.data?.map(mm => mm.id)?.includes(m.id))?.id 
                })) ?.reduce((p, c) => (c?.sku && p?.map(m => m.sku)?.includes(c?.sku)) ? p : [...p, c] , [])
                
                console.log("parsedResult", parsedResult)
                setProducts( parsedResult )
            setLoading(false)
        }
    }
    
    const sortProducts = (a, b) => {

        if(sortOption === 'price-asc'){
            return a.price - b.price
        }
        if(sortOption === 'price-desc'){
            return b.price - a.price
        }
        if(sortOption === 'name-asc'){
            return a?.title?.toLowerCase().localeCompare(b?.title?.toLowerCase());
            
        }
        if(sortOption === 'name-desc'){
            return b?.title?.toLowerCase().localeCompare(a?.title?.toLowerCase());
        }

        return 0;
    }

    useEffect(() => {init()},[]) 

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const searched = queryParams.get('search');
        // console.log('SSS:', searched);
        setSearch(searched)
    }, [location.search]); 

    const filterExpression = item => {
        return ( !search || Object.keys(item).filter(k => `${ item[k] }`.toLowerCase().indexOf(search.toLowerCase()) !== -1 ).length > 0)
    }

    const filterCategory = f => {
        return !categories || !cat?.length || categories?.filter(ff => cat?.includes(ff.id))?.map(ct => 
            ct?.products?.data?.map(m => m?.id)
        )?.reduce((p, c) => [...p,...c])?.includes(f?.id)
    }

    const filterSupplier = f => {
        return !sup?.length || sup?.includes(f?.source)
    }

    return (
        <>
            <ContainerAuthenticated>
                <FormContent>

                    { ( loading ) ? <LoadCenter> <Load black /> </LoadCenter> : <></> }
                    
                    <TitleContent>
                        <Content>
                            {
                                search ? 
                                <Title noSpace>Resultado da busca "{search}"</Title>
                                    :
                                <Title noSpace>Lista de produtos</Title>
                            }
                            <ProductQuantity>{products?.filter(filterExpression)?.length||0} Produtos encontrados</ProductQuantity>
                        </Content>
                        <Select
                            placeholder="Ordenar produtos por"
                            options={sortOptions}
                            value={sortOption}
                            onChange={handleSortChange}
                        />
                    </TitleContent>
                    <BodyContent>
                        {windowSize.width < 600 && (
                            <ButtonLink onClick={handleResponsive}>Ver filtros</ButtonLink>
                        )}
                        {(windowSize.width > 600 || isResponsiveActive) && (
                            <SideBar isResponsiveActive={isResponsiveActive} handleResponsive={handleResponsive} />
                        )}
                        <ProductContent>
                            {products?.sort(sortProducts)?.filter(filterCategory)?.filter(filterSupplier)?.filter(filterExpression)?.map((tire, index) => (
                                <ProductCard big key={index} {...tire} />
                            ))}
                        </ProductContent>
                    </BodyContent>

                </FormContent>
                <Brands />
                <PaymentMethods />
                <Footer />
            </ContainerAuthenticated>
        </>
    );
}