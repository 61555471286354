import React, { useContext } from 'react'

import { FormContent, ImgContainer, BrandsContainer, Img, BrandsContent } from './styled'
import { Title } from 'ui/styled'
import { parseStrapiImage } from 'utils'
import { CoreContext } from 'context/CoreContext'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'



export default function Brands() {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  const { brands } = useContext(CoreContext) 

  const handleClick = (brand) => {
    const formattedBrandName = brand?.title?.replace(/\s+/g, '-');
    navigate(`brand/${brand?.id}/${formattedBrandName}`)
  };

  return !brands?.filter(f => !!f?.brand_image?.data?.attributes?.url).length ? null : (
    <>
      <BrandsContent>
        <FormContent>
          <Title>Compre por marca</Title>
          <BrandsContainer>
            {brands?.filter(f => !!f?.brand_image?.data?.attributes?.url)?.slice(0,4)?.map((it, key) => (
              <ImgContainer key={key} onClick={() => handleClick(it)}>
                <Img src={parseStrapiImage(it?.brand_image?.data?.attributes?.url)} alt={it?.title} />
              </ImgContainer>
            ))}
          </BrandsContainer>
        </FormContent>
      </BrandsContent>
    </>
  )
}
