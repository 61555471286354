import React, { useContext, useMemo, useState } from 'react';
import { FormSpace, Icon, Overlay, SideContent, SideOptions, SideOptionsContent, SideTitle } from './styled';
import { sidebarOptions } from 'utils/options';
import Check from 'components/Form/Check';
import Scroll from 'components/Scroll';
import useWindowSize from 'utils/useWindowSize';
import { CoreContext } from 'context/CoreContext';


export default function SideBar({ isResponsiveActive, handleResponsive }) {
  const [active, setActive] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const windowSize = useWindowSize();

  const { categories, cat, setCat, sup, setSup } = useContext(CoreContext)


  const sideOp = useMemo(() => {
    return sidebarOptions?.map(m => ( m?.id === 1 ? { ...m, options: categories } : m ))
  }, [categories])

  const handleQuestionClick = (id) => {
    if (active.includes(id)) {
      setActive(active.filter(optionId => optionId !== id));
    } else {
      setActive([...active, id]);
    }
  };

  const handleCheckChange = (option, subOption, checked) => {

    if(option?.type === 'categories'){
      setCat( cat?.includes(subOption?.id) ? cat?.filter(f => f !== subOption?.id) : [ ...cat, subOption?.id ] )
    }

    if(option?.type === 'suppliers'){
      setSup( sup?.includes(subOption?.id) ? sup?.filter(f => f !== subOption?.id) : [ ...sup, subOption?.id ] )
    }

    // setCheckedItems({
    //   ...checkedItems,
    //   [optionName]: checked
    // });

    handleResponsive();
  };

  return (
    <>
      <Overlay active={isResponsiveActive} onClick={() => handleResponsive()} />

      <SideContent>
        {
          windowSize.width < 600 &&
          <Icon onClick={handleResponsive} src={'/icons/close.svg'} />
        }
        {sideOp.map((option, index) => (
          <SideOptionsContent key={index} top={index === 0}>
            <SideTitle onClick={() => handleQuestionClick(option.id)}>
              {option.title}
            </SideTitle>
            <Scroll maxHeight="240px" >
              {option.options.map((subOption, subIndex) => (
                <SideOptions key={subIndex}>
                  <Check
                    label={ subOption.title }
                    checked={ option?.type === 'categories' ? cat?.includes(subOption?.id) : sup?.includes(subOption?.id) }
                    onChange={() => handleCheckChange(option, subOption)}
                    small
                  />
                </SideOptions>
              ))}
            </Scroll>
            <FormSpace />
          </SideOptionsContent>
        ))}
      </SideContent>
    </>
  );
}