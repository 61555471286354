import React, { useEffect, useMemo, useState } from 'react'
import ContainerAuthenticated from 'containers/Authenticated'
import CarouselApp from 'components/carousel'
import { FormContent } from 'ui/styled';
import { useParams } from 'react-router-dom';
import { BrandContent, BrandText, Title, TextContainer, LegacyText, LegacyContainer, LegacyImg, DrinkContainer } from './styled';
import Video from 'components/Video';
import History from 'components/History';
import ProductList from 'components/ProductList';
// import { drinks } from 'utils/drinks';
import PaymentMethods from 'components/PaymentMethods';
import Footer from 'components/Footer';
import { Read, ReadOne } from 'services/about-brands';
import { normalizeStrapiList, normalizeStrapiRegister, parseStrapiImage } from 'utils';
import { Read as ReadSkus } from "services/skus";

export default function BrandDetails() {

  const { id, name } = useParams()

  const [brand, setBrand] = useState(null)
  const [relatedProducts, setRelatedProducts] = useState([])

  const brandName = useMemo(() => {
    return name
    .replace(/[^\w\s]/gi, '')
    .replace('-', '')
    .toLowerCase();
  }, [name])
  
  const brandNameRender = useMemo(() => {
    return name.replace('-', ' ').replace(/\b\w/g, char => char.toUpperCase());
  }, [name])

  const productDetails = useMemo(() => {
    console.log("brand", brand)
    return {
      banner: [
        ...(brand?.banner||[])?.map((m) => ( {
          url: parseStrapiImage(m?.url),
          alt: ' '
        }))
      ],
      title: brand?.title,
      description: brand?.description,
      // video: "/images/video.mp4", // Caminho para o vídeo
      video: parseStrapiImage(brand?.video?.url), // Caminho para o vídeo
      family_title: brand?.family_title,
      family_description: brand?.family_description,
      family_images: [
        ...(brand?.family_images||[])?.map((m) => parseStrapiImage(m?.url))
      ],
      history_title: brand?.hisotry_title,
      history_images: [

        ...(brand?.history_images||[])?.map((m) => ({
          year: m?.title,
          description: m?.description,
          image: parseStrapiImage(m?.image?.data?.attributes?.url)
        }))

      ],
      product_image: [
        ...(brand?.product_image||[])?.map((m) => ( {
          url: parseStrapiImage(m?.url),
          alt: ' '
        }))
      ],
    }

  }, [brand])

  const init = async () => {
    if(id){
      const result = await ReadOne(id)
      const normalResult = normalizeStrapiRegister(result) 
      setBrand( normalResult )


      const result_sku = await ReadSkus()
      const normalResult_sku = normalizeStrapiList(result_sku)

      if(normalResult?.related_products?.length){
        const parsedResult = normalResult?.related_products?.map(m => ({ 
            ...m, sku: normalResult_sku
            ?.find(f => f?.products?.data?.map(mm => mm.id)?.includes(m.id))?.id 
        })) ?.reduce((p, c) => (c?.sku && p?.map(m => m.sku)?.includes(c?.sku)) ? p : [...p, c] , [])

        setRelatedProducts(parsedResult)
      }


    }
  }

  useEffect(() => { init() ;}, [id])


  return (
    <>
      <ContainerAuthenticated noHeader>
        <CarouselApp imgs={productDetails.banner} />
        <BrandContent>
          <FormContent>
            <TextContainer>
              <Title brand={brandName}>
                {brandNameRender}®
              </Title>
              <BrandText>
                {productDetails.description}
              </BrandText>
            </TextContainer>
            <Video src={productDetails.video} />
          </FormContent>
        </BrandContent>
        <FormContent>
          <Title brand={brandName}>{productDetails.family_title}</Title>
          <LegacyText>
            {productDetails.family_description}
          </LegacyText>
          <LegacyContainer>
            {
              productDetails.family_images.map((image, index) => (
                <LegacyImg key={index} src={image} />
              ))
            }
          </LegacyContainer>
        </FormContent>
        <History history_images={productDetails.history_images} history_title={productDetails.history_title} brand={brandName} />
        <DrinkContainer>
          <FormContent>
            <CarouselApp imgs={productDetails.product_image} />
          </FormContent>
        </DrinkContainer>
        <ProductList title="Produtos relacionados" products={relatedProducts||[]} />
        <PaymentMethods />
        <Footer />

      </ContainerAuthenticated>
    </>
  )
}
