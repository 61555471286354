import { GET, POST, PUT, DELETE } from './api'

export const Create = async (params) => {
    return await POST(`/products`, params);
}

export const Read = async () => {
    return await GET(`/products?populate=*`);
}

export const ReadOne = async (id) => {
    return await GET(`/products/${id}?populate[0]=drinks&populate[1]=flavor_image&populate[2]=related_products&populate[3]=banner&populate[4]=product_specifications`);
}

export const Update = async (params, id) => {
    return await PUT(`/products/${id}`, params);
}

export const Delete = async (id) => {
    return await DELETE(`/products/${ id }`);
} 
 