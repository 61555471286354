import React from 'react'
import { ContentIcon, FooterContainer, FooterContent, FormContent, InfoContent, InfoFooter, InfoFooterContent, InfoText, SocialContent, Text, TextContainer, Title } from './styled'
import { Icon } from 'ui/styled'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Footer({ noInfo, noFooter, light }) {

  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  const footerOptions = [
    {
      title: "Sobre",
      options: [
        { text: "Sobre nós", onClick: () => navigate("about") },
        { text: "Política de Privacidade", onClick: () => navigate("terms") },
        // { text: "Atendimento ao cliente", onClick: () => console.log('Navegar para Atendimento ao cliente') },
        // { text: "Fale conosco", onClick: () => console.log('Navegar para Fale conosco') },
      ]
    },
    // {
    //   title: "Informações",
    //   options: [
    //     { text: "Televendas", onClick: () => console.log('Navegar para Televendas') },
    //     { text: "Troca e devolução", onClick: () => console.log('Navegar para Troca e devolução') },
    //     { text: "Termos e regulamentos", onClick: () => console.log('Navegar para Termos e regulamentos') },
    //   ]
    // },
    // {
    //   title: "Minha conta",
    //   options: [
    //     { text: "Meus dados", onClick: () => console.log('Navegar para Meus dados') },
    //     { text: "Acompanhe seu pedido", onClick: () => console.log('Navegar para Acompanhe seu pedido') },000
    //   ]
    // },
    {
      // title: "Confira agora nossas redes sociais",
      title: "Siga o Prime Spirits no Instagram",
      options: [
      ],
      social: [
        // { icon: 'facebook', onClick: () => console.log('Navegar para Facebook') },
        // { icon: 'youtube', onClick: () => console.log('Navegar para Youtube') },
        // { icon: 'twitter', onClick: () => console.log('Navegar para Twitter') },
        { icon: 'instagram', onClick: () => window.open("https://www.instagram.com/primespiritsclub/", "new") },
      ]
    }
  ];


  return (
    <>
      {noFooter ? null : <FooterContent>
        <FormContent>
          <ContentIcon>
            <Icon src="/icons/footer-logo.svg" alt="logo" onClick={() => window.scrollTo(0, 0)} />
          </ContentIcon>
          {
            footerOptions.map((option, index) => (
              <InfoContent key={index}>
                <Title>{option.title}</Title>
                {
                  option.options.map((opt, index) => (
                    <Text key={index} onClick={opt.onClick}>{opt.text}</Text>
                  ))
                }
                <SocialContent key={index}>
                  {
                    option.social && option.social.map((social, index) => (
                      <Icon src={`/icons/${social.icon}.svg`} alt={social.icon} onClick={social.onClick} />
                    ))
                  }
                </SocialContent>
              </InfoContent>
            ))
          }
        </FormContent>
      </FooterContent>}
      {noInfo ? null : <InfoFooter light={light}>
        <InfoFooterContent>

          {/* <FooterContainer>
            <TextContainer>
              <InfoText light={light}>Av. alameda,  12 - Conjunto 1, Sala 5 | Parque centro | Cidade/ES</InfoText>
              <InfoText light={light}>Nome da empresa LTDA - CNPJ: 12.345.678.0001-12</InfoText>
            </TextContainer>
          </FooterContainer> */}
          <InfoText light={light} marginTop>© Prime Spirits Club 2024</InfoText>
        </InfoFooterContent>
      </InfoFooter>}
    </>
  )
}
