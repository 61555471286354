import { GET, POST, PUT, DELETE } from './api'

export const Create = async (params) => {
    return await POST(`/about-brands`, params);
}

export const Read = async () => {
    return await GET(`/about-brands?populate=*`);
}

export const ReadOne = async (id) => {
    return await GET(`/about-brands/${id}?populate[0]=banner&populate[1]=video&populate[2]=family_images&populate[3]=product_image&populate[4]=history_images.image&populate[5]=related_products`);
}

export const Update = async (params, id) => {
    return await PUT(`/about-brands/${id}`, params);
}

export const Delete = async (id) => {
    return await DELETE(`/about-brands/${ id }`);
} 
 