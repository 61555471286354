import React, { useState } from 'react'

import { Content, Title, Container, LoginContent } from './styled'
import Button from 'components/Form/Button'
import { FormContent } from 'ui/styled'
import Input from 'components/Form/Input'
import { toast } from 'react-toastify'
import { isEmail } from 'utils/validators'
import { Create } from 'services/newsletters'
import { exposeStrapiError } from 'utils'

import { useTrackingCode } from "react-hubspot-tracking-code-hook";

export default function SuggestionNewsLetter() {

  const { setTrackEvent, setIdentity, setPathPageView, setContentType } = useTrackingCode({ hubspotId:"46178129" });
  const [loading, setLoading] = useState(false)

  const [form, setForm] = useState({})
  const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
  const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

  const valid = (verbose = false) => {

    if (!formValue('name') || !formValue('name').length) {
        if (verbose) { toast.error('Informe o nome para assinar'); }
        return false;
    }

    if (!formValue('email') || !formValue('email').length) {
        if (verbose) { toast.error('Informe o email para assinar'); }
        return false;
    }

    if (! isEmail( formValue('email') ) ) {
        if (verbose) { toast.error('Informe um email válido para assinar'); }
        return false;
    }

    return true
}

  const save = async () => {
    if (!valid(true)) { return; }
    if(!loading){
      setLoading(true)
        setPathPageView("/home");    
        setIdentity(form?.email);
        setContentType("landing-page");

        setTrackEvent({ eventId: "newsletter", value: form?.email })

        const result = await Create({ data:{ ...form } })
        if(!exposeStrapiError(result)){
          toast.success("News assinado com sucesso")
        }
      setLoading(false)
    }
  }

  return (
    <>
      <Content>
        <FormContent>
          <Container>
            <Title>Assine nossa newsletter e receba dicas, promoções e experiências na sua caixa de entrada </Title>
            <LoginContent>
              <Input placeholder="Nome" holder value={formValue('name')} onChange={e => changeForm(e.target.value, 'name')} noClear noRadius />
              <Input placeholder="E-mail" holder value={formValue('email')} onChange={e => changeForm(e.target.value, 'email')} noClear noRadius />
              <Button nospace primary loading={loading} onClick={save}>
                Assine
              </Button>
            </LoginContent>
          </Container>
        </FormContent >
      </Content>
    </>
  )
}
