import React, { useContext, useState } from 'react';
import { OriginalPrice, Discount, PaymentConditions, Price, SellerContainer, SellerNameContent, ShippingDetails, ShippingMethod, DiscountContent, SellerPriceContainer, ShippingMethodContainer } from './styled';
import Button from 'components/Form/Button';
import Check from 'components/Form/Check';
import { Content } from 'ui/styled';
import { useHistory } from 'react-router-dom';
import { CoreContext } from 'context/CoreContext';
import { parseCurrency } from 'utils/parsers';



const SellerDetails = ({ sellers, selected, setSelected }) => {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);
  const [active, setActive] = useState(false);

  const { cart, setCart } = useContext(CoreContext)

  const buy = async (product) => {

    if(product?.source === 'menimports'){
      const qs = `sc=1&sku=${ product?.ref_id }&qty=1&seller=1` 
      window.location.href = (`https://www.menimport.com.br/checkout/cart/add?${qs}`)
    }

    if(product?.source === 'snapzap'){

      // sem suporte para varios itens ao mesmo tempo

      document.getElementById("cart-item").value = `${product?.item_group_id}` // 187376812
      document.getElementById("cart-variant").value = product?.ref_id
      document.getElementById("form-snapzap").submit()
    }

  }

  const addToCart = (product) => {
    setCart([ ...cart, { ...product, quantity:1} ])
    navigate('cart')
  }

  return (
    <>
      {sellers.map((seller, index) => (
        <SellerContainer key={index}>
          <SellerNameContent>
            <Check checked={selected === index} label={`Vendido e entregue por ${seller.name}`} onChange={() => setSelected(index)} />
          </SellerNameContent>
          <SellerPriceContainer>

            <DiscountContent>
              { seller.originalPrice && <OriginalPrice>{parseCurrency(seller.originalPrice)}</OriginalPrice> }
              { seller.discount && <Discount>{seller.discount} OFF</Discount> }
            </DiscountContent>
            <Content>
              <Price >{parseCurrency(seller.price)}</Price>
              <PaymentConditions>{seller.paymentConditions}</PaymentConditions>
            </Content>
          </SellerPriceContainer>
          <ShippingMethodContainer>

            <ShippingMethod>{seller.shippingMethod}</ShippingMethod>
            {
              !seller?.shippingDetails ? null :
              <ShippingDetails>
                {seller.shippingDetails.type} ({seller.shippingDetails.duration}) por: R$ {seller.shippingDetails.cost}
              </ShippingDetails>
            }
          </ShippingMethodContainer>
          {
            (selected === index)  ? <>
                {
                  seller?.source === 'menimports' ? 
                    <Button outline primary onClick={() => addToCart(seller)}>Adicionar ao carrinho</Button>
                  : null 
                }
                <Button primary onClick={() => buy(seller)}>Comprar</Button>
            </> : null
          }
        </SellerContainer>
      ))}
      <form target="snapzap" action='https://www.snapzap.com.br/comprar/' method='post' id='form-snapzap' style={{ height: 0, overflow: "hidden", opacity: 0 }}>
        <input id='cart-item' type="text" name="add_to_cart" value="" /> 
        <input id='cart-variant' type="text" name="variant_id" value="" /> 
        <input id='cart-quantity' type="text" name="quantity" value="1" /> 
        {/* <input id='cart-quantity' type="text" name="add_to_cart_enhanced" value="1" />  */}
      </form>
    </>
  );
};

export default SellerDetails;