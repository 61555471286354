import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';
import { CustomCarousel, Icon, Img } from './styled';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function CarouselApp({ imgs, pdp }) {

  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  }; 

  return !imgs?.length || !(typeof imgs?.map === 'function') ? null : (
  <>
  
      {
        !pdp ? null :
        imgs.map((img, index) =>
            <Img
              key={index}
              onClick={() => navigate('products')}
              src={img.url}
              alt={img.alt}
            /> 
        )
      }

      {
        !!pdp ? null :
          <CustomCarousel
            activeIndex={activeIndex}
            onSelect={handleSelect}
            nextIcon={<Icon src='/icons/next.svg' />}
            prevIcon={<Icon src='/icons/back.svg' />}
            indicators={true}
          >
            {
              imgs.map((img, index) =>
                <Carousel.Item key={index}>
                  <Img
                    onClick={() => navigate('products')}
                    src={img.url}
                    alt={img.alt}
                  />
                </Carousel.Item>
              )
            }
          </CustomCarousel>
      }

    </>
  );
}