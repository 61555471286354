import React, { useEffect, useRef, useState } from "react";

import Header from 'components/Dashboard/Header'

import {
    DashboardPage,
    DashboardBody,
    DashboardBodyContent,
    Content,
    HeaderContainer
} from "./styled";
import { ReadObject } from "services/storage";
import { useHistory } from "react-router-dom";
import { ThemedComponent } from "ui/theme";
import AgeVerificationForm from "components/AgeVerificationForm";


export default function ContainerAuthenticated({ children, noHeader }) {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);
    const init = () => {
        const authentication = ReadObject('authentication')
        if (!authentication?.jwt) {
            completeNext()
        }
    }

    const [ageActive, setAgeActive] = useState(true);
    const handleAgeActive = () => {
        setAgeActive(false);
    }

    const completeNext = () => {
        // navigate('login')
    }

    useEffect(() => {
        init()
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <ThemedComponent>
                <Content>
                    <DashboardPage>
                        <HeaderContainer>
                            <Header />
                        </HeaderContainer>
                        <DashboardBody >
                            <DashboardBodyContent>
                                {children}
                            </DashboardBodyContent>
                        </DashboardBody>
                    </DashboardPage>
                </Content>
                {ageActive && <AgeVerificationForm handleAgeActive={handleAgeActive} />}
            </ThemedComponent>
        </>
    );
}