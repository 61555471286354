import React, { useEffect, useMemo, useState } from "react";

import {
    FormContent,
    ProductContent,
    ProductDetailsContent,
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";

import Footer from "components/Footer";
import Breadcrumb from "components/Breadcrumb";
import ImageGallery from "components/ImageGallery";
import ProductInfo from "components/ProductInfo";
import ProductDescription from "components/ProductDescription";
import useWindowSize from "utils/useWindowSize";
import ProductList from "components/ProductList";
import { drinks } from "utils/drinks";
import { products } from "utils/products";
import Banner from "components/Banner";
import FlavorProfile from "components/FlavorProfile";
import DrinksCarousel from "components/DrinksCarousel";

import { ReadOne } from "services/products";
import { Read as ReadSkus } from "services/skus";

import { normalizeStrapiList, normalizeStrapiRegister, parseStrapiImage } from "utils";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";



export default function ProductsDetails() {
    const windowSize = useWindowSize();

    const { id } = useParams()

    const [product, setProduct] = useState(null)
    const [loading, setLoading] = useState(false)
    
    const [relatedProducts, setRelatedProducts] = useState([])
    const [skus, setSkus] = useState([])


    const images = useMemo(() => {
        return !product?.image_link ? [] : [ product?.image_link ]
    }, [product]);
    // const product = products[0];

    const flavorImage = "/images/flavor.svg";

    const init = async () => {
        if(!loading && id){
            setLoading(true)

                const result_sku = await ReadSkus()
                const normalResult_sku = normalizeStrapiList(result_sku)
                console.log("normalResultSkus", normalResult_sku) 

                const result = await ReadOne(id)
                const normalResult = normalizeStrapiRegister(result)
                console.log("normalResult", normalResult)


                const haveSkus = normalResult_sku?.find(f => f?.products?.data?.map(mm => mm.id)?.includes(normalResult.id))
                if(haveSkus){
                    console.log("haveSkus", normalizeStrapiList(haveSkus?.products) )
                    setSkus( normalizeStrapiList(haveSkus?.products) )
                }

                setProduct(normalResult)
 
                if(normalResult?.related_products?.length){
                    const parsedResult = normalResult?.related_products?.map(m => ({ 
                        ...m, sku: normalResult_sku
                        ?.find(f => f?.products?.data?.map(mm => mm.id)?.includes(m.id))?.id 
                    })) ?.reduce((p, c) => (c?.sku && p?.map(m => m.sku)?.includes(c?.sku)) ? p : [...p, c] , [])

                    setRelatedProducts(parsedResult)
                }

            setLoading(false)
        }
    }

    useEffect(() => {init()},[id]) 

    return (
        <>
            <ContainerAuthenticated>
                <ProductDetailsContent>
                    <FormContent>
                        <Breadcrumb />
                        <ProductContent>
                            <ImageGallery images={images} />
                            <ProductInfo {...product} product={product} skus={skus} />
                        </ProductContent>
                    </FormContent>
                    <Banner product={product} />
                </ProductDetailsContent>

                <ProductDescription product={product} />
                { !product?.flavor_image?.url ? null : <FlavorProfile flavorImage={parseStrapiImage(product?.flavor_image?.url)} /> }
                { !product?.drinks?.url ? null : <DrinksCarousel pdp images={[ { url: parseStrapiImage(product?.drinks?.url), alt:" " } ]} /> }
                <ProductList title="Produtos relacionados" products={relatedProducts||[]} />

                <Footer noInfo />
            </ContainerAuthenticated>
        </>
    );
}