import React, { useMemo, useState } from 'react'
import { FormContent, ProductTitle, ProductinfoContent, Text } from './styled'

import ShippingDeadline from 'components/ShippingDeadline'
import SellerDetails from 'components/SellerDetails'
import { suppliers } from 'utils/options';

export default function ProductInfo({ price, id, title, product, skus}) {



  const [selected, setSelected] = useState(0);

  const [selectedOption, setSelectedOption] = useState(1);

  const handleChange = (qt) => {
    setSelectedOption(qt);
  };

  const sellers = useMemo(() => 
    skus?.length ? 
      skus?.map( m => ({
        name: suppliers[m?.source] || "",
        price: m?.price,
        discount: "0%",
        originalPrice: m?.price, 
        shippingMethod: " ", 
        ...m
      }) 
    ) : [
    {
      name: suppliers[product?.source] || "",
      price: price,
      discount: "0%",
      originalPrice: price,
      // paymentConditions: "ou 12x de R$1.234,56 sem juros",
      shippingMethod: " ",
      ...product
      // shippingDetails: {
      //   type: "Sedex",
      //   duration: "até 12 dias",
      //   cost: 12.34
      // }
    }, 
  ], [skus]);
  return (
    <>
      <FormContent>
        <ProductinfoContent noSpace>
          <ProductTitle>{title}</ProductTitle>
          {/* <ShippingDeadline /> */}
          { skus?.length ? <Text>Escolha um dos fornecedores abaixo e compre</Text> : null }
          <Text> </Text>
          <SellerDetails sellers={sellers} selected={selected} setSelected={setSelected} />
        </ProductinfoContent>
      </FormContent>

    </>
  )
}
