import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import NotFound from 'screens/NotFound'

import Login from 'screens/Authentication/Login'
import Register from 'screens/Authentication/Register'
import Forgot from 'screens/Authentication/Forgot'

import DashboardHome from 'screens/Dashboard/Home'
import DashboardMe from 'screens/Dashboard/Me'
import Products from "screens/Products";
import ProductsDetails from "screens/ProductsDetails";
import Cart from "screens/Cart";
import Checkout from "screens/Checkout";
import OrderDetails from "screens/OrderDetails";
import ToRecoverPassword from "screens/ToRecoverPassword";
import BrandDetails from "screens/BrandDetails";

import Terms from "screens/Institutional/Terms";
import About from "screens/Institutional/About";


export default function AppRouter() {
  return (
    <Router>
      <div>
        <Switch>

          <Route path="/" exact> <DashboardHome /> </Route>
          <Route path="/products" exact> <Products /> </Route>
          <Route path="/products/:id" exact> <ProductsDetails /> </Route>
          <Route path="/products/:id/:slug" exact> <ProductsDetails /> </Route>


          <Route path="/login" exact> <Login /> </Route>
          <Route path="/register" exact> <Register /> </Route>
          <Route path="/forgot" exact> <Forgot /> </Route>
          <Route path="/ToRecoverPassword" exact> <ToRecoverPassword /> </Route>

          <Route path="/dashboard" exact> <DashboardHome /> </Route>
          <Route path="/dashboard/Me" exact> <DashboardMe /> </Route>

          <Route path="/cart" exact> <Cart /> </Route>
          <Route path="/checkout" exact> <Checkout /> </Route>
          <Route path="/order/:id" exact> <OrderDetails /> </Route>
          <Route path="/brand/:name" exact> <BrandDetails /> </Route>
          <Route path="/brand/:id/:name" exact> <BrandDetails /> </Route>
          
          
          <Route path="/terms" exact> <Terms /> </Route>
          <Route path="/about" exact> <About /> </Route>

          <Route path="*" exact> <NotFound /> </Route>
        </Switch>
      </div>
    </Router>
  );
}