import React, { useState, createContext, useEffect } from 'react'
import { ReadObject, SaveObject } from '../services/storage'
 
export const CoreContext = createContext({})

export const CoreState = ({ children }) => {

	const url = new URL(window.location.href);
  
	const [cat, setCat] = useState( url?.searchParams?.get('category') ? [ parseInt(url?.searchParams?.get('category')) ]  : [])
	const [sup, setSup] = useState([])
      
	const [ user, setUser ] = useState( ReadObject('user') ? ReadObject('user') : [])  
	const [ brands, setBrands ] = useState( ReadObject('brands') ? ReadObject('brands') : [])  
	const [ categories, setCategories ] = useState( ReadObject('categories') ? ReadObject('categories') : [])  
	const [ cart, setCart ] = useState( ReadObject('cart') ? ReadObject('cart') : [])  
	const [ agree, setAgree ] = useState( ReadObject('agree') ? ReadObject('agree') : false)  

	const contextValue = {  
		user, setUser,
		agree, setAgree,
		cart, setCart,
		
		categories, setCategories,
		brands, setBrands,

		cat, setCat,
		sup, setSup
	}

	// to persist state when app reload  
    useEffect(() => { SaveObject('user', user) ;}, [user]) 
    useEffect(() => { SaveObject('brands', brands) ;}, [brands]) 
    useEffect(() => { SaveObject('categories', categories) ;}, [categories]) 
    useEffect(() => { SaveObject('cart', cart) ;}, [cart]) 
    useEffect(() => { SaveObject('agree', agree) ;}, [agree]) 

	return <CoreContext.Provider value={contextValue}>{children}</CoreContext.Provider>
}
